<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>Legal Info</v-expansion-panel-header>
      <v-expansion-panel-content></v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "LegalInfo"
}
</script>

<style scoped>

</style>